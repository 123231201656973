import React from "react";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Make sure to adjust the import paths to match where you store these images
import appleBadge from "./assets/AppleAppStore.png";
import googleBadge from "./assets/GooglePlay.png";

const InstallMobileApp: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const appleAppStoreUrl =
    "https://apps.apple.com/us/app/crewtimer-regatta-timing/id1450216565?ls=1";
  const googlePlayStoreUrl =
    "https://play.google.com/store/apps/details?id=net.entazza.crewtimer";

  return (
    <Container maxWidth="md" sx={{ textAlign: "center", py: 4 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        CrewTimer Mobile App
      </Typography>

      <Typography variant="body1" sx={{ mb: 4 }}>
        Select your store below to get started.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        {/* Apple Store */}
        <Box
          component="a"
          href={appleAppStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ display: "inline-block" }}
        >
          <Box
            component="img"
            src={appleBadge}
            alt="Download on the App Store"
            sx={{
              height: isSmallScreen ? "60px" : "90px",
              cursor: "pointer",
            }}
          />
        </Box>

        {/* Google Play Store */}
        <Box
          component="a"
          href={googlePlayStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ display: "inline-block" }}
        >
          <Box
            component="img"
            src={googleBadge}
            alt="Get it on Google Play"
            sx={{
              height: isSmallScreen ? "60px" : "90px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default InstallMobileApp;
