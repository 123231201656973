import React, { useEffect, useRef, useState } from "react";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import ResultsPage from "./ResultsPage";
import EventResults from "./shared/EventResults";
import About from "./shared/About";
import { useKiosk, useKioskGreen } from "./shared/UseKiosk";
import Nav from "./Nav";
import Page404 from "./shared/Page404";
import {
  Route,
  BrowserRouter as Router,
  useLocation,
  Routes,
  Navigate,
} from "react-router-dom";
import { blue } from "@mui/material/colors";
import VersionPage from "./VersionPage";
import { useElapsedClock } from "./shared/UseElapsedClock";
import Contact from "./shared/Contact";
import Privacy from "./shared/Privacy";
import TermsOfUse from "./shared/TermsOfUse";
import Store from "./shared/Store";
import { UrlTracker } from "./shared/UrlTracker";
import Markdown from "./shared/Markdown";
import Help from "./shared/Help";
import { Points } from "./components/Points";
import { setPrintPending } from "./shared/UsePrintPending";
import { HeatSheet, RegistrationPacket } from "./components/Heatsheet";
import { EventSchedule } from "./components/EventSchedule";
import LiveStreamEvent from "./components/LiveStreamEvent";
import InstallMobileApp from "./InstallMobileApp";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// https://stackoverflow.com/questions/25646502/how-to-render-repeating-elements
// See sites/examples/ExamplesPage.js for how container is resized

// firebase deploy: https://www.codementor.io/yurio/all-you-need-is-react-firebase-4v7g9p4kf

// https://medium.com/@liangchun/using-react-toolbox-with-create-react-app-f4c2a529949

// material-ui with create-react-app: https://stackoverflow.com/questions/44192576/use-create-react-app-with-material-uihttps://github.com/firebase/functions-samples/tree/master/quickstarts/uppercase

// mobile detect: https://github.com/hgoebl/mobile-detect.js/

// themes: http://www.material-ui.com/#/customization/themes

const theme = createTheme({
  palette: {
    primary: { main: blue[300] },
    secondary: { main: blue[700] },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "20px",
        },
        head: {
          height: "20px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "2px 0.5em",
        },
        body: {
          fontSize: "1em",
        },
        head: {
          fontSize: "1em",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: "#00000000",
          color: "#000000de",
          borderColor: "#0000003b",
        },
      },
    },
  },
});

const API = () => {
  return <Markdown docId="CrewTimerAPI.md" />;
};

const AboutDeveloper = () => {
  return <Markdown docId="CrewTimerAboutDevelper.md" />;
};

const AppContent = () => {
  const location = useLocation();
  const [, setKiosk] = useKiosk();
  const [, setElapsedClock] = useElapsedClock();
  const [kioskGreen, setKioskGreen] = useKioskGreen();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setKiosk(
      location.pathname.indexOf("/kiosk") > 0 ||
        searchParams.get("kiosk") === "true"
    );
    setKioskGreen(location.pathname.indexOf("/green") > 0);
    setElapsedClock(location.pathname.indexOf("/elapsed") > 0);
  }, [
    location.pathname,
    location.search,
    setKiosk,
    setKioskGreen,
    setElapsedClock,
  ]);

  useEffect(() => {
    const handlePrint = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        // Handle the Control/Command+P keystroke here
        console.log("Control/Command+P detected");
        event.preventDefault();
        setPrintPending(true);
        setTimeout(() => {
          window.print();
          setPrintPending(false);
        }, 1000);
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "f") {
        // Render all events to allow search to work
        setPrintPending(true);
      }
    };

    document.addEventListener("keydown", handlePrint);
    return () => document.removeEventListener("keydown", handlePrint);
  }, []);

  return (
    <div
      className="app-container"
      /** app-container style adds height: 100vh when not printing */
      style={
        kioskGreen
          ? { background: "green", display: "flex", flexDirection: "column" }
          : { display: "flex", flexDirection: "column" }
      }
    >
      {/* <Routes>
        <Route path="/regatta/*" element={<Nav title={regattaTitle} infoText={infoText} />} />
        <Route path="/" element={<Nav title={title} infoText="" />} />
      </Routes> */}
      <Routes>
        <Route
          path="/"
          element={<ResultsPage onRegattaSelected={() => {}} />}
        />
        <Route path="/regatta/*" element={<EventResults />} />
        <Route path="/tracking/*" element={<EventResults />} />
        <Route path="/schedule/*" element={<EventSchedule />} />
        <Route path="/live-stream/*" element={<LiveStreamEvent />} />
        <Route path="/live/*" element={<LiveStreamEvent />} />
        <Route path="/heatsheet/*" element={<HeatSheet />} />
        <Route path="/packet/*" element={<RegistrationPacket />} />
        <Route path="/points/*" element={<Points />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/store" element={<Store />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/about" element={<About />} />
        <Route path="/help/*" element={<Help />} />
        <Route path="/version" element={<VersionPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/api" element={<API />} />
        <Route path="/appstore/*" element={<InstallMobileApp />} />
        <Route
          path="/progression-engine"
          element={<Navigate replace to="/help/Progressions" />}
        />
        <Route path="/about-developer" element={<AboutDeveloper />} />
        <Route element={<Page404 />} />
      </Routes>
    </div>
  );
};

const App = () => {
  const [, setTableWidth] = useState(0); // triggers render
  const updateTimer = useRef<any>();
  const onResize = () => {
    clearTimeout(updateTimer.current);
    updateTimer.current = setTimeout(
      () => setTableWidth(window.innerWidth),
      16
    );
  };

  useEffect(() => {
    const win = window;
    if (win.addEventListener) {
      win.addEventListener("resize", onResize, false);
      // } else if (win.attachEvent) {
      //   win.attachEvent('onresize', onResize);
    } else {
      win.onresize = onResize;
    }
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <UrlTracker />
            <Nav />
            <AppContent />
          </Router>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
